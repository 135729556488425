import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { albumsContainer, albumBox, albumCover } from '../../components/music.module.css'

const MusicPage = ({ data }) => {
  return (
    <div>
      <title>Music &ndash; Evan Gedrich</title>
      <ul className={albumsContainer}>
      {data.allMdx.nodes.map(node => (
        <Link key={node.id} to={`/music/${node.slug}`} className={albumBox}>
          <li>
            <div className={albumCover}><GatsbyImage image={getImage(node.frontmatter.cover)} alt={node.frontmatter.title}></GatsbyImage></div>
            <p>{node.frontmatter.title}<br/><span>{node.frontmatter.year}</span></p>
          </li>
        </Link>
      ))}
      </ul>
    </div>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___year, order: DESC}) {
      nodes {
        frontmatter {
          year
          title
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
              )
            }
          }
        }
        id
        body
        slug
      }
    }
  }
`

export default MusicPage
